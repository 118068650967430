import React from 'react'
import Layout from '../components/layout'
import SEO from "../components/seo"
import chaiProfile from "../images/profile.jpg"

const AboutPage = () => (
    <Layout>
        <SEO title='About' description='Chai Chuah' />
        <div className="container py-6">
            <div className="row justify-content-center">
                <div className="col-lg-8">
                    <h1 className="display-4 mb-4">About Chai</h1>
                    <hr className="border-1 border-light my-4 ml-0 text-left w-15" role="presentation" />
                    <p className="lead text-secondary">I love to learn, collaborate and create.</p>
                </div>
            </div>
        </div>
        <div className="bg-white text-black" style={{'font-size': '18px'}}>
            <div className="container py-2">
                <div className="bg-white mt-n6 px-3 px-lg-0">
                    <div className="row justify-content-center py-4 py-lg-5 px-4">
                        <div className="col-md-6">
                            <p className="mt-3">Chai Chuah was appointed Director-General of Health in March 2015, after acting in the role for 16 months. Originally from Malaysia, Chai studied Commerce at Canterbury University before commencing a career with PricewaterhouseCoopers in New Zealand and internationally.</p>
                            <p>He has been a prominent figure in the New Zealand health sector for 25 years, first with Canterbury DHB where he was Chief Financial Officer, Chief Operating Manager and acting Chief Executive, before spending over seven years as Chief Executive of Hutt Valley DHB. He has been in national leadership roles with the Ministry of Health since 2010 when he became National Director of the National Health Board.</p>
                            <p>He has a passion for building a health system together with other partners, which is powered by the needs of the people it serves and which is prepared for rapid changes in technology and demographics. </p>
                            <p>He is focused on driving change in the way the health system works with other public services, communities and other non-public services partners to improve health outcomes, increase access to quality care, improve financial and clinical sustainability, and to develop a unified health system.</p>
                            <p>Contact Chai at <a href="mailto:chai@chaichuah.com">chai@chaichuah.com.</a></p>
                        </div>
                        <div className="col-md-6">
                            <img src={chaiProfile} alt="chai chuah profile" />
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div className="bg-primary text-dark text-center">
            <div className="container py-6">
                <h2>Newsletter subscription</h2>
                <p className="mb-4">Subscribe to our newsletter to receive news & updates.</p>
                <form>
                    <div className="form-row justify-content-center align-items-center">
                        <div className="col-md-4">
                            <label className="sr-only" htmlFor="inputName">First name</label>
                            <input type="text" className="form-control border-0 font-italic mb-3" id="inputName" placeholder="First Name" />
                        </div>
                        <div className="col-md-4">
                            <label className="sr-only" htmlFor="inputEmail">Email address</label>
                            <input type="email" className="form-control border-0 font-italic mb-3" id="inputEmail" placeholder="Email Address" />
                        </div>
                        <div className="col-md-auto">
                            <button type="submit" className="btn btn-outline-dark mb-3">Sign Up</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </Layout>
)

export default AboutPage